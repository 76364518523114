import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
	A1NavigationButtonProps,
	A1NavigationCountProps,
	A2NavigationArrowShapeProps,
	A2NavigationBlockProps,
} from './types';
import { IconButton } from '../../components/button';

const A2NavigationBlock = ({ children, dark, carousel }: PropsWithChildren<A2NavigationBlockProps>) => (
	<nav className={clsx('a2-navigation', {
		'a2-navigation--dark': dark,
		'a2-navigation--carousel': carousel,
	})}>
		{children}
	</nav>
);

const A2NavigationButton = ({ children, onClick, disabled, screenReaderText }: PropsWithChildren<A1NavigationButtonProps>) => (
	<IconButton
		disabled={disabled}
		sizeMedium
		noHoverEffect
		screenReaderText={screenReaderText}
		onClick={onClick}
	>
		{children}
	</IconButton>
);

const A2NavigationArrowShape = ({ mirrored, dimmed, thin, large }: A2NavigationArrowShapeProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 12 12"
		className={clsx('a2-navigation__shape', {
			'a2-navigation__shape--mirrored': mirrored,
			'a2-navigation__shape--large': large,
		})}
	>
		<path
			d="m 4 1 l 5 5 l -5 5"
			className={clsx('a2-navigation__shape-path', {
				'a2-navigation__shape-path--dimmed': dimmed,
				'a2-navigation__shape-path--thin': thin,
			})}
		/>
	</svg>
);

const A2NavigationCount = ({ count }: A1NavigationCountProps) => {
	return (
		<span className="a2-navigation__count">
			{count.toLocaleString('da-DK', { minimumIntegerDigits: 2 })}
		</span>
	);
};

const A2NavigationRuler = () => (
	<hr className="a2-navigation__ruler" />
);

export {
	A2NavigationBlock,
	A2NavigationButton,
	A2NavigationCount,
	A2NavigationRuler,
	A2NavigationArrowShape,
};
