import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
import { Draggable } from 'gsap/Draggable';
import {InertiaPlugin } from 'gsap/InertiaPlugin';
import clsx from 'clsx';
import tinycolor from 'tinycolor2';
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import { graphql } from 'gatsby';
import { useReactiveVar } from '@apollo/client';
import { darkModeEnabledVar } from '../apollo/cache';
import { useFileSource, useMediaMatchesResize } from '../hooks';
import { curtainAnimation } from '../utils';
import Layout from '../components/layout';
import Meta from '../components/meta';
import {
	SliderBlock,
	SliderList,
	SliderItem,
	SliderFigure,
	SliderImage,
	SliderText,
	SliderTitle,
	SliderTitleGroup,
	SliderNavigation,
	SliderNavigationItem,
	SliderNavigationButton,
} from '../components/slider';
import {
	A2NavigationBlock,
	A2NavigationButton,
	A2NavigationCount,
	A2NavigationRuler,
	A2NavigationArrowShape,
} from '../components/a2-navigation';

const IndexPage = ({ data, path }) => {
	const {
		home,
		allFullscreenImages,
	} = data;
	const {
		meta_title,
		meta_description,
		meta_image,
		mobile_next_button_label,
		mobile_previous_button_label,
		grid_overview_button_label,
		carousel_overview_button_label,
		link_items,
	} = JSON.parse(home.content);
	const metaImage = useFileSource(allFullscreenImages, meta_image.filename);
	const curtainRef = useRef<HTMLDivElement>(null);
	const [showOverview, setShowOverview] = useState(false);
	const [panningFocus, setPanningFocus] = useState('center');
	const [flipLayout, setFlipLayout] = useState(null);
	const enableDarkMode = useReactiveVar(darkModeEnabledVar);
	const isMobile = useMediaMatchesResize('(max-width: 640px)', 100);
	const scope = useRef<HTMLDivElement>(null);
	const proxy = useRef<HTMLDivElement>(null);
	const tl = useRef(null);
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [screenWidth, setScreenWidth] = useState(0);

	const resetSlidePosition = () => {
		const selector = gsap.utils.selector(scope.current);
		gsap.set(selector('.slider__item'), { clearProps: 'all' });
	};

	const changeBackground = () => {
		if (link_items) {
			if (link_items[currentSlideIndex]) {
				const backgroundColorValue = link_items[currentSlideIndex].background_color ? link_items[currentSlideIndex].background_color : '#ffffff';
				const tinyColor = tinycolor(backgroundColorValue);
				darkModeEnabledVar(tinyColor.isDark());
				document.body.style.backgroundColor = backgroundColorValue;
			}
		}
	};

	const addClassesToBody = () => {
		document.body.classList.add('transition-colors');
		document.body.classList.add('duration-750');
		document.body.classList.add('ease-in-out');
	};

	const removeClassesToBody = () => {
		document.body.classList.remove('transition-colors');
		document.body.classList.remove('duration-750');
		document.body.classList.remove('ease-in-out');
	};

	const prepareOverviewTransition = () => {
		const selector = gsap.utils.selector(scope.current);
		const sliderItems = selector('.slider__item');
		resetSlidePosition();

		const state = Flip.getState(sliderItems, { props: 'width, height, x, y' });
		setPanningFocus('center');
		setShowOverview(prev => !prev);
		setFlipLayout(state);
	};

	const animateOverviewTransition = () => {
		if (!flipLayout) return null;
		const selector = gsap.utils.selector(scope.current);

		const headerGroupsTimeline = gsap.timeline();
		const titles = selector('.slider__title');
		const subtitles = selector('.slider__text');
		const headerGroupItems = showOverview ? titles : [titles, subtitles];

		Flip.from(flipLayout, {
			duration: 2,
			stagger: .15,
			ease: 'power1.inOut',
			onStart: () => {
				headerGroupsTimeline
					.set(headerGroupItems, { opacity: 0 }, 0)
					.to(headerGroupItems, { opacity: 1, duration: .75 }, 1);
			},
			onComplete: () => {
				if(!showOverview) {
					goToSlide(currentSlideIndex);
				}
			},
		});
	};

	const verticalPanningHandler = (event: React.MouseEvent) => {
		const { clientY } = event;
		const windowHeight = window.innerHeight;
		const verticalBoundary = windowHeight / 4;
		const panningTopLimit = verticalBoundary;
		const panningBottomLimit = windowHeight - verticalBoundary; 
		
		if (clientY < panningTopLimit) return setPanningFocus('top');
		if (clientY > panningBottomLimit) return setPanningFocus('bottom');
		return setPanningFocus('center');
	};

	const horizontalPanningHandler = (index: number) => {
		if (index > currentSlideIndex) return setPanningFocus('right');
		if (index < currentSlideIndex) return setPanningFocus('left');
		return setPanningFocus('center');
	};

	const updateSlider = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		changeBackground();
	}, [currentSlideIndex]);

	// Register plugins
	useLayoutEffect(() => {
		gsap.registerPlugin(Draggable, InertiaPlugin, Flip);

		proxy.current = document.createElement('div');
		const selector = gsap.utils.selector(scope.current);
		const slides_count = selector('.slider__item').length;
		const slideWidth = selector('.slider__item')[0].clientWidth;

		const context = gsap.context(() => {
			tl.current = gsap.to(selector('.slider__item'), {
				ease: 'none',
				x: `-=${slideWidth * (slides_count - 1)}`,
				duration: 1,
				paused: true,
				repeat: -1,
			});

			const updateProgress = () => {
				gsap.killTweensOf(tl);
				tl.current.progress(gsap.getProperty(proxy.current, 'x') as number / -(slideWidth * (slides_count - 1)));
				const index = Math.abs(Math.round(gsap.getProperty(proxy.current, 'x') as number / -slideWidth));
				setCurrentSlideIndex(index);
			};

			const snapbox = gsap.utils.snap(slideWidth);

			Draggable.create(proxy.current, {
				trigger: '.slider',
				inertia: true,
				throwProps: true,
				type: 'x',
				edgeResistance: 0.65,
				bounds: {
					minX: 0,
					maxX: slideWidth * -(slides_count - 1),
				},
				onDrag: updateProgress,
				onThrowUpdate: updateProgress,
				snap: { x: snapbox },
			});
		}, scope);
		
		return () => context.revert();
	}, [screenWidth]);

	/****
	 *  Handles the click event on the buttons
	 * by taking the direction, a calculation on the next step in the timeline is made.
	 * The timeline is reused and the progress is set to the next step.
	 * A object is created and we use the gsap.to method to animate the timeline to the next step.
	 * This way the draggable element will follow the timeline.
	 * As a side effect we want to change the background color of the body.
	****/
	const handleSlideChange = (direction: number) => {
		if((direction === -1 && tl.current.progress() > 0) ||
			(direction === 1 && tl.current.progress() < 1)) {
			const current = tl.current.progress();
			const selector = gsap.utils.selector(scope.current);
			const width = selector('.slider__item')[0].clientWidth;
			const slides_count = selector('.slider__item').length - 1;
			const nextSlide = gsap.utils.snap(1 / slides_count, current + ((1 / slides_count ) * direction));
			const el = { x: current };
			const nextIndex = nextSlide * slides_count;
			setCurrentSlideIndex(nextIndex);
			gsap.to(el, { x: nextSlide, duration: 1, onUpdate: () => tl.current.progress(el.x) });
			gsap.set(proxy.current, { x: -width * (slides_count - 1) * nextSlide });
		}
	};

	const goToSlide = (slide: number) => {
		const selector = gsap.utils.selector(scope.current);
		const slides_count = selector('.slider__item').length - 1;
		const width = selector('.slider__item')[0].clientWidth;
		const nextSlide = gsap.utils.clamp(0, 1, gsap.utils.snap(1 / slides_count, slide === 0 ? 0 : slide / slides_count));
		const el = { x: 0 };
		setCurrentSlideIndex(slide);
		gsap.to(el, { x: nextSlide, duration: 1, onUpdate: () => tl.current.progress(el.x) });
		gsap.set(proxy.current, { x: -width * slides_count * nextSlide });
	};

	useEffect(() => {
		animateOverviewTransition();
	}, [flipLayout]);

	useEffect(() => {
		window.addEventListener('resize', updateSlider);
		return () => {
			window.removeEventListener('resize', updateSlider);
		};
	}, []);

	useEffect(() => {
		addClassesToBody();
		return () => {
			removeClassesToBody();
			darkModeEnabledVar(true);
			document.body.removeAttribute('style');
		};
	}, []);

	return (
		<Layout path={path}>
			<>
				<Meta
					language="en"
					title={meta_title}
					description={meta_description}
					ogImage={metaImage?.node?.publicURL || meta_image?.filename}
					hrefLangEn="https://eu.iconsbymenu.com"
					hrefLangEnUs="https://us.iconsbymenu.com"
					hrefLangUrlXDefault="https://eu.iconsbymenu.com"
				/>
				<div
					ref={scope}
					className={clsx({'px-30 lg:px-60': showOverview })}
				>
					<SliderBlock overview={showOverview}>
						<SliderList>
							{link_items.map(({link: { cached_url }, title, subtitle, image: { alt, filename }, _uid}, index) => (
								<SliderItem
									key={_uid}
									onMouseEnter={() => showOverview ? null : horizontalPanningHandler(index)}
									onMouseLeave={() => showOverview ? null : horizontalPanningHandler(index)}
									onMouseMove={event => (index === currentSlideIndex && !showOverview) ? verticalPanningHandler(event) : null}
									active={index === currentSlideIndex && !showOverview}
									panningFocus={panningFocus}
								>
									<TransitionLink
										to={(index === currentSlideIndex || showOverview) ? cached_url : ''}
										onClick={() => handleSlideChange(currentSlideIndex > index ? -1 : 1 )}
										exit={{
											length: 1,
											trigger: ({ exit, node }) => {
												curtainAnimation({
													props: exit,
													node,
													transitionDirection: 'up',
												}, curtainRef.current);
											},
										}}
										entry={{delay: 1 / 2}}
									>
										<SliderFigure>
											{filename && (
												<SliderImage src={filename} alt={alt} />
											)}
										</SliderFigure>
										<SliderTitleGroup dark={enableDarkMode}>
											<SliderTitle>
												{title}
											</SliderTitle>
											{!showOverview && (
												<SliderText>
													{subtitle}
												</SliderText>
											)}
										</SliderTitleGroup>
									</TransitionLink>
								</SliderItem>
							))}
						</SliderList>
					</SliderBlock>
				</div>
				<TransitionPortal>
					<div ref={curtainRef} className="transition-portal" />
				</TransitionPortal>
				{!showOverview && isMobile && (
					<A2NavigationBlock dark={enableDarkMode} carousel>
						<A2NavigationButton
							screenReaderText={mobile_previous_button_label}
							disabled={currentSlideIndex === 0}
							onClick={() => handleSlideChange(-1)}
						>
							<A2NavigationArrowShape mirrored />
						</A2NavigationButton>
						<A2NavigationCount count={currentSlideIndex + 1} />
						<A2NavigationRuler />
						<A2NavigationCount count={link_items.length} />
						<A2NavigationButton
							screenReaderText={mobile_next_button_label}
							disabled={currentSlideIndex === link_items.length - 1}
							onClick={() => handleSlideChange(1)}
						>
							<A2NavigationArrowShape />
						</A2NavigationButton>
					</A2NavigationBlock>
				)}
				{!showOverview && !isMobile && (
					<SliderNavigation>
						{currentSlideIndex > 0 && (
							<SliderNavigationItem fadeIn={panningFocus === 'left'}>
								<SliderNavigationButton onClick={() => handleSlideChange(-1)} dark={enableDarkMode}>
									<A2NavigationArrowShape mirrored dimmed thin large />
									<span className='sr-only'>{mobile_previous_button_label}</span>
								</SliderNavigationButton>
							</SliderNavigationItem>
						)}
						{currentSlideIndex < link_items.length - 1 && (
							<SliderNavigationItem right fadeIn={panningFocus === 'right'}>
								<SliderNavigationButton onClick={() => handleSlideChange(1)} dark={enableDarkMode}>
									<A2NavigationArrowShape dimmed thin large />
									<span className='sr-only'>{mobile_next_button_label}</span>
								</SliderNavigationButton>
							</SliderNavigationItem>
						)}
					</SliderNavigation>
				)}
				<div className={clsx('font-sans-serif text-10 fixed lg:bottom-50 top-90 lg:top-auto lg:right-60 right-30 transition-filter ease-in-out duration-750', {
					'invert': enableDarkMode,
				})}>
					<button onClick={() => prepareOverviewTransition()} className='flex items-center font-semibold uppercase tracking-22'>
						<span className='mt-2 mr-16'>
							{showOverview ? carousel_overview_button_label : grid_overview_button_label}
						</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox={showOverview ? '0 0 15 12' : '0 0 40 8'}
							className={showOverview ? 'w-15 h-12' : 'w-40 h-9'}
						>
							<path
								d={showOverview
									? 'm 0 0 h 15 v 12 h -15 z'
									: 'm 0 0 h 9 v 8 h -9 z'
								}
								fill="#000"
							/>
							{!showOverview && (
								<>
									<path d='m 15 0 h 9 v 8 h -9 z' fill='#000' />
									<path d='m 30 0 h 9 v 8 h -9 z' fill='#000' />
								</>
							)}
						</svg>
					</button>
				</div>
			</>
		</Layout>
	);
};

export default IndexPage;

export const homeQuery = graphql`
	query HomeQuery {
		home: storyblokEntry(full_slug: {eq: "home"}) {
			content
		}
		collections: allStoryblokEntry(filter: {field_component: {eq: "collection"}}) {
			edges {
				node {
					full_slug
					content
				}
			}
		}
		allFullscreenImages: allFile(filter: {extension: {in: ["png", "jpg"]}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], layout: FULL_WIDTH)
					}
					id
					name
					extension
					publicURL
				}
			}
		}
	}
`;
