import React from 'react';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import type {
	SlideImageProps,
	SliderListProps,
	SliderItemProps,
	SliderNavigationItemProps,
	SliderNavigationButtonProps,
	SliderTitleGroupProps,
	SliderBlockProps,
} from './types';

const SliderBlock = ({ children, overview }: PropsWithChildren<SliderBlockProps>) => (
	<div className={clsx('slider', {
		'slider--overview': overview,
	})}>
		{children}
	</div>
);

const SliderList = ({ children, style }: PropsWithChildren<SliderListProps>) => (
	<ul className='slider__list' style={style}>
		{children}
	</ul>
);

const SliderItem = ({ children, active, onMouseEnter, onMouseLeave, onMouseMove, panningFocus}: PropsWithChildren<SliderItemProps>) => (
	<li
		className={clsx('slider__item', {
			'slider__item--infocus': active,
			'slider__item--top-panning': panningFocus === 'top',
			'slider__item--bottom-panning': panningFocus === 'bottom',
			'slider__item--left-panning': panningFocus === 'left',
			'slider__item--right-panning': panningFocus === 'right',
		})}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		onMouseMove={onMouseMove}
	>
		{children}
	</li>
);

const SliderFigure = ({ children }: PropsWithChildren<unknown>) => (
	<figure className='slider__figure'>
		{children}
	</figure>
);

const SliderImage = ({ src, alt }: SlideImageProps) => (
	<img className='slider__image' src={src} alt={alt} />
);

const SliderTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className='slider__title'>
		{children}
	</h1>
);

const SliderText = ({ children }: PropsWithChildren<unknown>) => (
	<p className='slider__text'>
		{children}
	</p>
);

const SliderTitleGroup = ({ children, dark }: PropsWithChildren<SliderTitleGroupProps>) => (
	<hgroup className={clsx('slider__title-group', {
		'slider__title-group--dark': dark,
	})}>
		{children}
	</hgroup>
);

const SliderNavigation = ({ children }: PropsWithChildren<unknown>) => (
	<menu className='slider__navigation'>
		{children}
	</menu>
);

const SliderNavigationItem = ({ children, right, fadeIn }: PropsWithChildren<SliderNavigationItemProps>) => (
	<li className={clsx('slider__navigation-item', {
		'slider__navigation-item--right': right,
		'slider__navigation-item--fade-in': fadeIn,
	})}>
		{children}
	</li>
);

const SliderNavigationButton = ({ children, onClick, dark }: PropsWithChildren<SliderNavigationButtonProps>) => (
	<button
		onClick={onClick}
		className={clsx('slider__navigation-button', {
			'slider__navigation-button--dark': dark,
		})}
	>
		{children}
	</button>
);

export {
	SliderBlock,
	SliderList,
	SliderItem,
	SliderFigure,
	SliderImage,
	SliderTitle,
	SliderText,
	SliderTitleGroup,
	SliderNavigation,
	SliderNavigationItem,
	SliderNavigationButton,
};
